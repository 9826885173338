<template>
  <footer class="footer">
    <b-container>

      <b-row>

        <b-col lg="3" md="3" sm="6" class="mb-lg-0 mb-4">
            <div v-bind:class="{footer_logo:'show'}">
                <img src="../assets/img/logo.svg" alt="">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut </p>
            </div>
        </b-col>

        <b-col lg="3" md="3" sm="6" class="mb-lg-0 mb-4">
          <div v-bind:class="{info:'show'}">
            <div v-bind:class="{pi_title:'show'}">
              <h3>Contact Us</h3>
            </div>
            <ul class="m-0 p-0 list-unstyled info">
              <li><a href="mailto:hello@mobileappwork.com"><i class="fa fa-envelope" aria-hidden="true"></i><small>hello@mobileappwork.com</small></a></li>
              <li><a href="tel:+919924546764"><i class="fas fa-phone"></i><small>+91 99245 46764</small></a></li>
            </ul>
            <div class="socal-icon-main">
                <ul class="m-0 p-0 list-unstyled d-flex">
                    <li><a href="#"><i class="fab fa-facebook"></i></a></li>
                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                    <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                </ul>
            </div>
          </div>
        </b-col>

        <b-col  lg="3" md="3" sm="6" class="mb-lg-0 mb-4">
          <div v-bind:class="{info:'show'}">
            <div v-bind:class="{pi_title:'show'}">
              <h3>Useful Links</h3>
            </div>
            <ul class="m-0 p-0 list-unstyled menu">
              <li><a class="nav-item" href="#home">Home</a></li>
              <li><a class="nav-item" href="#About">About</a></li>
              <li><a class="nav-item" href="#features">Features</a></li>
              <li><a class="nav-item" href="#service">Service</a></li>
              <li><a class="nav-item" href="#Work">Work</a></li>
              <li><a class="nav-item" href="#testimonial">Testimonial</a></li>
              <li><a class="nav-item" href="#contact">Contact</a></li>
            </ul>
          </div>
        </b-col>
        
        <b-col  lg="3" md="3" sm="6" class="mb-lg-0 mb-4">
          <div v-bind:class="{info:'show'}">
            <div v-bind:class="{pi_title:'show'}">
              <h3>App Store</h3>
            </div>
            <ul class="m-0 p-0 list-unstyled play-store">
              <li>
                <a href="https://play.google.com/store/apps/dev?id=5197856095394170562&hl=en-GB" target="_blank"><img src="../assets/img/googleplay_icon.webp" alt=""></a>
              </li>
              <li>
                <a href="https://apps.apple.com/in/developer/perfection-infoweb-pvt-ltd/id1200697413" target="_blank"><img src="../assets/img/images.webp" alt=""></a>
              </li>
            </ul>
          </div>
        </b-col>

      </b-row>

    <b-row class="footer-bottom d-flex justify-content-md-between mt-lg-5 mt-2">
        <b-col  sm="6" class="text-sm-left text-center">
            <p> Copyright 2021,All Right Reserved</p>
        </b-col>
        <b-col  sm="6" class="text-sm-right text-center">
            <p><a href="privacy-policy.html" target="_blank">Privacy Policy Terms of Service</a></p>
        </b-col>
    </b-row>

    </b-container>
  </footer>
</template>
<script>
    export default{
        name:"Footer"
    }
</script>

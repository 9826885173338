<template>
    <div class="Clients mt-5 mb-lg-5 pt-lg-4 mp-box" id="testimonial">
        <b-container>
            <b-row align-v="center">
                <b-col lg="12" xl="12">
                    <div class="mobileapp-title text-center">
                        <h3>What Our Clients Say’s</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                    </div>
                </b-col>
                <b-col lg="12" xl="12">
                    <div v-bind:class="{gallery_wrapper:true}">

                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client2-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet,</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client3-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client3-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client3-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut Lorem</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client2-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client2-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client2-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client2-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet,</p>  
                        </div>
                        <div v-bind:class="{gallery_info:true}">
                            <div v-bind:class="{img_main:true}">
                                <div v-bind:class="{name_itam:true}">
                                    <img src="../assets/img/client2-img.png" alt="">
                                    <div v-bind:class="{name:true}">
                                        <h5>Smith</h5>
                                        <span>@rachsmith</span>
                                    </div>
                                </div>
                                <div v-bind:class="{icon:true}">
                                    <i class="fa-solid fa-gear"></i>
                                </div>
                            </div>  
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet,</p>  
                        </div>
                        
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"HowWork"
    }

</script>
    
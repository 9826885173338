<template>
    <div class="header_menu py-2">

        <b-container>
           <div class="top-header">
                    <nav class="flex container p-0">
                        <ul class="flex flex-grow space-x-4 m-0">
                            <li>
                                <a href="/" class="flex items-center space-x-3 text-gray-700 md:mr-5">
                                    <img alt="Vue logo" src="../assets/img/logo.svg">
                                </a>
                            </li>
                        </ul>
                        <ul class="flex items-center space-x-5 m-0 d-lg-inline-flex d-none menu">
                            <li class="nav-item"><a class="active" href="#home">Home</a></li>
                            <li class="nav-item"><a href="#features">Features</a></li>
                            <li class="nav-item"><a href="#Work">Work</a></li>
                            <li class="nav-item"><a href="#About">About</a></li>
                            <li class="nav-item"><a href="#service">service</a></li>
                            <li class="nav-item"><a href="#testimonial">Testimonial</a></li>
                            <li class="nav-item btn_border"><a href="#contact">Request Quote</a></li>
                        </ul>
                        <ul class="flex mobile_menu md:hidden" @click="showMenu = !showMenu">
                            <li>
                                <a href="#" class="flex-none absolute right-0 px-5 z-30">
                                    <p class="text-gray-200 font-semibold tracking-wide hover:bg-gray-900 hover:bg-opacity-30 rounded px-2 py-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" ></path>
                                        </svg>
                                    </p>
                                </a>

                                <div class="absolute top-0 right-0 z-10 h-screen w-full bg-black transition" :class="{'invisible opacity-0': !showMenu,'visible opacity-40': showMenu,}"></div>
                                <div class="absolute right-0 z-20 w-full px-4 mt-16 transition" :class="{ 'invisible opacity-0': !showMenu, 'visible opacity-100': showMenu,}">
                                <ul class="p-3 flex flex-col bg-gray-100 w-full rounded shadow-md overflow-hidden menu">
                                    <li class="nav-item"><a class="active" href="#home">Home</a></li>
                                    <li class="nav-item"><a href="#features">Features</a></li>
                                    <li class="nav-item"><a href="#Work">Work</a></li>
                                    <li class="nav-item"><a href="#About">About</a></li>
                                    <li class="nav-item"><a href="#service">service</a></li>
                                    <li class="nav-item"><a href="#testimonial">Testimonial</a></li>
                                    <li class="nav-item btn_border"><a href="#contact">Request Quote</a></li>
                                </ul>
                                </div>
                            </li>
                        </ul>
                    </nav>
            </div>
            
        </b-container>

    </div>
</template>

<script>

    export default{
        name:"AppHeader",
        data() {
            return {
                showMenu: false,
            }
        },
    }

</script>
<template>
    <div>
        <HomeBanner /> 
        <ToolsSection />
        <TrustedClients />
        <HowWork />
        <AboutUs />
        <Services />
        <Projects />
        <Clients />
        <Contact />
        <Logoslider />
        <Singup />
    </div>
</template>  
<script>

    import HomeBanner from '@/components/HomeBanner.vue'
    import ToolsSection from '@/components/ToolsSection.vue'
    import TrustedClients from '@/components/TrustedClients.vue'
    import HowWork from '@/components/HowWork.vue'
    import AboutUs from '@/components/AboutUs.vue'
    import Services from '@/components/Services.vue'
    import Projects from '@/components/Projects.vue'
    import Clients from '@/components/Clients.vue'
    import Contact from '@/components/Contact.vue'
    import Logoslider from '@/components/Logoslider.vue'
    import Singup from '@/components/Singup.vue'
    

    export default{
        name:"Home",
        components: {
            HomeBanner,
            ToolsSection,
            TrustedClients,
            HowWork,
            AboutUs,
            Services,
            Projects,
            Clients,
            Contact,
            Logoslider,
            Singup
        }
    }

</script>


<template>
    <div class="Banner py-5 mp-box" id="home">
        <b-container>
            <b-row align-v="center">
                <b-col lg="12" xl="12">
                    <div class="info Title home_banner text-center">
                        <img class="m-auto" alt="Vue logo" src="../assets/img/banner-img.webp">
                        <h1 class="mt-5 mb-0">App development agency</h1>
                        <h5>One place for your small, Medium, and Enterprise business</h5>
                    </div>
                    <div class="sign-up">
                        <div id="mc_embed_signup" class="Signup__form btn text-center w-100 p-0">
                            <form action="https://mobileappwork.us19.list-manage.com/subscribe/post?u=208501480ddad5e61cf1282f1&amp;id=5e2a04d73f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>
                                <div id="mc_embed_signup_scroll">
                                    <div class="mc-field-group">
                                        <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
                                        <p>Signup with us. And get a 20% discount on your first work!</p>
                                    </div>
                                    <div id="mce-responses" class="clear foot">
                                        <div class="response" id="mce-error-response" style="display:none"></div>
                                        <div class="response" id="mce-success-response" style="display:none"></div>
                                    </div>
                                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_208501480ddad5e61cf1282f1_5e2a04d73f" tabindex="-1" value=""></div>
                                    <div class="optionalParent btn">
                                        <div class="clear foot">
                                            <input type="submit" value="Sign up" name="subscribe" id="mc-embedded-subscribe" class="btn mobile_button signup-btn btn-secondary">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </b-col>    
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"HomeBanner"
    }

</script>
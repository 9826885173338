<template>
    <div class="Tools_section my-5 mp-box">
        <b-container>
            <div class="mobileapp-title text-center">
                <h3>Projects we helped our clients <br> to achieve success.</h3>
            </div>
            <b-row align-v="center">
                <b-col lg="12" xl="12">
                    <b-tabs content-class="mt-3" align="center">
                        <b-tab title="Productivity" active>
                            <img class="w-100" alt="Vue logo" src="../assets/img/productbity1.webp">
                        </b-tab>
                        <b-tab title="Education">
                            <img class="w-100" alt="Vue logo" src="../assets/img/methbuddy.webp">
                        </b-tab>
                        <b-tab title="Journal">
                            <img class="w-100" alt="Vue logo" src="../assets/img/gemes.webp">
                        </b-tab>
                        <b-tab title="Food Delivery">
                            <img class="w-100" alt="Vue logo" src="../assets/img/jain.webp">
                        </b-tab>
                        <b-tab title="E-Commerce">
                            <img class="w-100" alt="Vue logo" src="../assets/img/e-coomers.webp">
                        </b-tab>
                        <b-tab title="Religion">
                            <img class="w-100" alt="Vue logo" src="../assets/img/jain.webp">
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"Projects"
    }

</script>
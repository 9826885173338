<template>
  <div id="app">
    <AppHeader />
    <router-view></router-view>
    <Footer />
    <a id="back-to-top" href="#" class="btn btn-light btn-lg back-to-top" role="button">
      <i class="fa fa-chevron-up" aria-hidden="true"></i>
    </a>
  </div>
</template>

<script>

import AppHeader from '@/components/AppHeader.vue'
import Footer from '@/components/Footer.vue'

  export default {  
    name: 'App',
    components: {
      AppHeader,
      Footer
    },
    metaInfo: {
      title: 'Mobileappwork',
      htmlAttrs: {
        lang: 'en-US'
      },
      meta: [
        { charset: 'UTF-8' },
        { name: 'description', content: 'An example Vue application with vue-meta.'},
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    },
  }
  
</script>


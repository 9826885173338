<template>
    <div class="Logo_slider my-5 mp-box">
        <b-container>
            <b-row align-v="center" class="w-100 m-0">
                <b-col md="12">
                    <div class="slider">
                        <div class="slide-track">
                            <div class="slide">
                                <img src="../assets/img/Colorzilla.webp" alt="">
                            </div>
                            <div class="slide">
                                <img src="../assets/img/Flashcard.webp" alt="">
                            </div>
                            <div class="slide">
                                <img src="../assets/img/Jainapp.webp" alt="">
                            </div>
                            <div class="slide">
                                <img src="../assets/img/Juniors-kiddo.webp" alt="">
                            </div>
                            <div class="slide">
                                <img src="../assets/img/Juniorskiddo-advanced-learning.webp" alt="">
                            </div>
                            <div class="slide">
                                <img src="../assets/img/Mathbuddy.webp" alt="">
                            </div>
                            <div class="slide">
                                <img src="../assets/img/Ohey-qr-code.webp" alt="">
                            </div>
                            <div class="slide">
                                <img src="../assets/img/Today-Rule.webp" alt="">
                            </div>
                            <div class="slide">
                                <img src="../assets/img/Whether.webp" alt="">
                            </div>
                            <div class="slide">
                                <img src="../assets/img/Color-fill.webp" alt="">
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"Logoslider"
    }

</script>
    
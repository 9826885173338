<template>
    <div class="How-work my-5 mp-box" id="Work">
        <b-container>
            <b-row align-v="center">
                <b-col lg="12" xl="12">
                    <div class="mobileapp-title text-center">
                        <h3>How it work</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </b-col>
                <div class="stat-box-container row align-items-stretch">
                    <div class="stat-box col-md-4 col-sm-6 col-12">
                        <div class="stat-icon">
                            <img class="w-100" alt="Vue logo" src="../assets/img/work02.webp">
                        </div>
                        <div class="stat-data">
                            <div class="stat-info">Download app</div>
                            <div class="stat-text">Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor.</div>
                        </div>
                    </div>
                    <div class="stat-box col-md-4 col-sm-6 col-12">
                        <div class="stat-icon">
                            <img class="w-100" alt="Vue logo" src="../assets/img/choose-2.png">   
                        </div>
                        <div class="stat-data">
                            <div class="stat-info">Choose plan</div>
                            <div class="stat-text">Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor.</div>
                        </div>
                    </div>
                    <div class="stat-box col-md-4 col-sm-6 col-12">
                        <div class="stat-icon">
                            <img class="w-100" alt="Vue logo" src="../assets/img/work01.webp"> 
                        </div>
                        <div class="stat-data">
                            <div class="stat-info">Scan Code</div>
                            <div class="stat-text">Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor.</div>
                        </div>
                    </div>
                    <div class="stat-box col-md-4 col-sm-6 col-12">
                        <div class="stat-icon">
                            <img class="w-100" alt="Vue logo" src="../assets/img/work01.webp"> 
                        </div>
                        <div class="stat-data">
                            <div class="stat-info">Download app</div>
                            <div class="stat-text">Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor.</div>
                        </div>
                    </div>
                    <div class="stat-box col-md-4 col-sm-6 col-12">
                        <div class="stat-icon">
                            <img class="w-100" alt="Vue logo" src="../assets/img/work04.webp"> 
                        </div>
                        <div class="stat-data">
                            <div class="stat-info">Done Installation</div>
                            <div class="stat-text">Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor.</div>
                        </div>
                    </div>
                    <div class="stat-box col-md-4 col-sm-6 col-12">
                        <div class="stat-icon">
                            <img class="w-100" alt="Vue logo" src="../assets/img/work02.webp">
                        </div>
                        <div class="stat-data">
                            <div class="stat-info">Download app</div>
                            <div class="stat-text">Lorem ipsum dolor sit amet, consectetur dipiscing elit, sed do eiusmod tempor.</div>
                        </div>
                    </div>

                    <!-- border -->
                    <div class="stat-decore d-h d-md-block d-none"></div>
                    <div class="stat-decore d-v d-md-block d-none"></div>
                    <!-- backgrund -->
                    <div class="background-box"></div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"HowWork"
    }

</script>
    
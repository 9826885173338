<template>
    <div class="Service my-5 mp-box" id="service">
        <b-container>
            <b-row align-v="center">
                <b-col lg="12" md="12">
                    <div class="mobileapp-title text-center">
                        <h3>Services We Provides</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </b-col>   
                <div class="stat-box-container row align-items-stretch">
                    <div class="stat-box col-md-4 col-sm-6 col-12 border">
                        <div class="box-info">
                            <div class="stat-icon d-flex align-items-center">
                                <img alt="Vue logo" src="../assets/img/ios.png">
                                <h4>iOS App Design</h4>
                            </div>
                            <div class="stat-data">
                                <div class="stat-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</div>
                                <div class="stat-info">Milica Radojevic</div>
                                <span>People Experience Partner, Blinkist</span>
                            </div>
                        </div>
                    </div>
                    <div class="stat-box col-md-4 col-sm-6 col-12 border-top border-bottom">
                        <div class="box-info">
                            <div class="stat-icon d-flex align-items-center">
                                <img alt="Vue logo" src="../assets/img/cop.png">
                                <h4>UI Design</h4>
                            </div>
                            <div class="stat-data">
                                <div class="stat-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</div>
                                <div class="stat-info">Milica Radojevic</div>
                                <span>People Experience Partner, Blinkist</span>
                            </div>
                        </div>
                    </div>
                    <div class="stat-box col-md-4 col-sm-6 col-12 border">
                        <div class="box-info">
                            <div class="stat-icon d-flex align-items-center">
                                <img alt="Vue logo" src="../assets/img/tools1.webp">
                                <h4>iOS App Design</h4>
                            </div>
                            <div class="stat-data">
                                <div class="stat-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</div>
                                <div class="stat-info">Milica Radojevic</div>
                                <span>People Experience Partner, Blinkist</span>
                            </div>
                        </div>
                    </div>
                    <div class="background-box"></div>
                </div>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"Services"
    }

</script>
    
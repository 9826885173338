<template>
    <div class="Trusted-clients mt-3 mb-5 mp-box"> 
        <b-container>
            <b-row align-v="center">
                <h3>Trusted by clients</h3>
                <b-col lg="12" xl="12">
                    <ul class="m-0 p-0 list-unstyled ">
                        <b-dropdown-item to="/"><img  alt="Vue logo" src="../assets/img/spotify.webp"></b-dropdown-item>
                        <b-dropdown-item to="/"><img  alt="Vue logo" src="../assets/img/loom.webp"></b-dropdown-item>
                        <b-dropdown-item to="/"><img  alt="Vue logo" src="../assets/img/slack.webp"></b-dropdown-item>
                        <b-dropdown-item to="/"><img  alt="Vue logo" src="../assets/img/Square.webp"></b-dropdown-item>
                        <b-dropdown-item to="/"><img  alt="Vue logo" src="../assets/img/spotify.webp"></b-dropdown-item>
                        <b-dropdown-item to="/"><img  alt="Vue logo" src="../assets/img/loom.webp"></b-dropdown-item>
                    </ul>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"TrustedClients"
    }

</script>
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import './plugins/bootstrap-vue'
import App from './App.vue'
import '@/assets/scss/_custom.scss'

// for link 
import VueRouter from 'vue-router'
import Home from './components/Home'  


Vue.use(VueRouter);
Vue.use(VueMeta);

const router= new VueRouter({
  mode: 'history',
  routes: [
    {path:'/',component:Home},
  ],
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

Vue.use(VueMeta, {
  keyName: 'head'
})

Vue.config.productionTip = false;
  new Vue({
    router, 
    el: '#app',
    render: h => h(App)
}).$mount("#app")




<template>
    <div class="Tools_section my-5 mp-box" id="features">
        <b-container>
            <b-row align-v="center">
                <b-col lg="12" xl="12">
                    <b-tabs content-class="mt-3" align="center">
                        <b-tab title="Team wiki" active>
                            <img class="w-100" alt="Vue logo" src="../assets/img/tab3.webp">
                        </b-tab>
                        <b-tab title="Projects & tasks">
                            <img class="w-100" alt="Vue logo" src="../assets/img/tab2.webp">
                        </b-tab>
                        <b-tab title="Notes & docs">
                            <img class="w-100" alt="Vue logo" src="../assets/img/tab3.webp">
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"ToolsSection"
    }

</script>
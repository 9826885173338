<template>
    <div class="Who-we-are my-5 mp-box" id="About">
        <b-container>
            <b-row align-v="center">
                <b-col lg="6" md="12" xl="6" class="order-lg-2">
                    <div class="mobileapp-title text-lg-left text-center">
                        <h3>Who we are?</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="count_info">
                        <div class="counter-main">
                            <div class="d-flex align-items-center justify-content-lg-start justify-content-center"> 
                                <div class="count">350</div>
                                <span>+</span>
                            </div>
                            <p>Completed projects</p>
                        </div>
                        <div class="counter-main">
                            <div class="d-flex align-items-center justify-content-lg-start justify-content-center"> 
                                <div class="count">25</div>
                                <span>+</span>
                            </div>
                            <p>Professional Team</p>
                        </div>
                        <div class="counter-main">
                            <div class="d-flex align-items-center justify-content-lg-start justify-content-center"> 
                                <div class="count">800</div>
                                <span>+</span>
                            </div>
                            <p>Happy customers</p>
                        </div>
                         <div class="counter-main">
                            <div class="d-flex align-items-center justify-content-lg-start justify-content-center"> 
                                <div class="count">10</div>
                                <span>+</span>
                            </div>
                            <p>Inprogress Project</p>
                        </div>
                    </div>
                </b-col>   
                <b-col lg="6" md="12" xl="6" class="order-lg-1">  
                    <div class="p-lg-2">
                        <img class="w-100" alt="Vue logo" src="../assets/img/we-are.webp">
                    </div>
                </b-col> 
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"AboutUs"
    }

</script>
    
<template>
    <div class="get-in-touch my-5 pt-lg-5" id="contact">
        <b-container>
            <b-row> 
                <b-col lg="6" xl="6">
                    <div class="mobileapp-title text-left">
                        <h3>Let's get in touch</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                        <h6>By 700+ customers for 3200+ Web and Mobile App development projects.</h6>
                    </div>
                </b-col>
                <b-col lg="6" xl="6">
                    <div v-bind:class="{form_main:true}">
                        <h4>Please fill in the form below or mail us your requirements :)</h4>
                        <div id="mc_embed_signup" class="w-100">
                            <form action="https://mobileappwork.us19.list-manage.com/subscribe/post?u=208501480ddad5e61cf1282f1&amp;id=bcd6ad64e8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate p-0" novalidate>
                                <div id="mc_embed_signup_scroll" class="row">
                                    <div class="col-lg-12 d-md-flex">
                                        <div class="mc-field-group col-lg-6 pl-0 mb-2 pr-md-3 pr-0">
                                            <input type="text" value="" name="FIRSTNAME" placeholder="First Name" class="required" id="mce-FIRSTNAME">
                                        </div>
                                        <div class="mc-field-group col-lg-6 pr-0 mb-2 pl-md-3 pl-0">
                                            <input type="text" value="" name="LASTNAME" placeholder="Last Name" class="required" id="mce-LASTNAME">
                                        </div>
                                    </div>
                                    <div class="mc-field-group col-lg-6 mb-2">
                                        <input type="text" name="PHONE" placeholder="Phone Number" class="required" value="" id="mce-PHONE">
                                    </div>
                                    <div class="mc-field-group col-lg-6 mb-2">
                                        <input type="email" value="" name="EMAIL" placeholder="Enter email" class="required email" id="mce-EMAIL">
                                    </div>
                                    <div class="mc-field-group col-lg-12 mb-2">
                                        <input type="text" value="" name="SUBJECT" placeholder="Subject" class="required" id="mce-SUBJECT">
                                    </div>
                                    <div class="mc-field-group col-lg-12 mb-2">
                                        <input type="text" value="" name="MESSAGE" placeholder="Enter something..." class="required" id="mce-MESSAGE">
                                    </div>
                                    <div class="mc-field-group col-lg-12 mb-2">
                                        <select name="ENQUIRY" class="required px-2" id="mce-ENQUIRY">
                                            <option value="Android Mobile Application">Android Mobile Application</option>
                                            <option value="iOS Mobile Application">iOS Mobile Application</option>
                                            <option value="Both Android &amp; iOS Mobile Application">Both Android &amp; iOS Mobile Application</option>
                                            <option value="Game Development">Game Development</option>
                                            <option value="Application Design">Application Design</option>
                                        </select>
                                    </div>
                                    <div class="mc-field-group col-lg-12 mb-2">
                                        <select name="BUDGET" class="required px-2" id="mce-BUDGET">
                                            <option value="Less than $5,000">Less than $5,000</option>
                                            <option value="$5,000 - $10,000">$5,000 - $10,000</option>
                                            <option value="$10,000 - $25,000">$10,000 - $25,000</option>
                                            <option value="$25,000 - $50,000">$25,000 - $50,000</option>
                                            <option value="$50,000 - $1,00,000">$50,000 - $1,00,000</option>
                                            <option value="$100,000+">$100,000+</option>
                                        </select>
                                    </div>
                                    <div id="mce-responses" class="clear foot">
                                        <div class="response" id="mce-error-response" style="display:none"></div>
                                        <div class="response" id="mce-success-response" style="display:none"></div>
                                    </div>
                                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_208501480ddad5e61cf1282f1_bcd6ad64e8" tabindex="-1" value=""></div>
                                    <div class="optionalParent btn">
                                        <div class="clear foot">
                                            <input type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe" class="mobile_button m-0 px-5">
                                            <p class="brandingLogo"><a href="http://eepurl.com/g6pVzL" title="Mailchimp - email marketing made easy and fun"></a></p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    export default{
        name:"Contact"
    }

</script>
    
<template>
    <div class="singup-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">

                    <div class="singup-main">
                        <h3>Signup to our newsletter</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                        <div id="mc_embed_signup">
                            <form action="https://mobileappwork.us19.list-manage.com/subscribe/post?u=208501480ddad5e61cf1282f1&amp;id=5e2a04d73f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>
                                <div id="mc_embed_signup_scroll">
                                    <div class="mc-field-group">
                                        <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
                                    </div>
                                    <div id="mce-responses" class="clear foot">
                                        <div class="response" id="mce-error-response" style="display:none"></div>
                                        <div class="response" id="mce-success-response" style="display:none"></div>
                                    </div>
                                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_208501480ddad5e61cf1282f1_5e2a04d73f" tabindex="-1" value=""></div>
                                    <div class="optionalParent btn">
                                        <div class="clear foot">
                                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="mobile_button m-0 px-4">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default{
        name:"Singup"
    }

</script>
    